import React, { useState, useEffect } from 'react';
import CategoryList from '../../components/CategoryList/';
import axios from 'axios';
import { TableLoader } from '../../components/ContentLoader';
import { alertLoadingError } from '../../components/Confirm';
import { useTranslation } from 'react-i18next';

const MediaHighResolution = ({ market }) => {
    const [imageFolders, setImageFolders] = useState(null)
    const [error, setError] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        axios({
            method: 'get',
            baseURL: process.env.REACT_APP_CONTURA_BASE_URL,
            url: '/rest-api/mediaApi/images',
            params: {
                market: market,
                contentType: 'prod'
            }
        }).then((res) => {
            setImageFolders(res.data[0].items);
        }).catch((error) => {
            setError(true)
            alertLoadingError(t)
        });
    }, [t]);

    const ExtendedCategoryList = ({ imageFolders }) => {
        return (
            <>
                {imageFolders.map((folder, index) => (
                    <div
                        key={index}
                        className="list-category-item list-category-item--extended">
                        <div className="list-category-title list-category-title--extended">
                            <h2>{folder.name}</h2>
                        </div>
                        <div className="">
                            <CategoryList items={folder.items} />
                        </div>
                    </div>
                ))}
            </>
        )
    };

    return (
        <div className="main-content">
            <div className="content-primary">
                <h1>
                    {t('media.high-res.title')}
                </h1>
                <p>
                    {t('media.high-res.intro')}
                </p>
            </div>
            {imageFolders &&
                <div className="content-secondary">
                    <div className="list-category-items list-category-items--extended">
                        <ExtendedCategoryList imageFolders={imageFolders} />
                    </div>
                </div>
            }
            {!imageFolders && !error &&
                <TableLoader />
            }
        </div>
    );
}

export default MediaHighResolution;