import React, { useEffect, useState } from 'react';
import axios from 'axios';
import VideoList from '../../components/Video/';
import { ProductListLoader } from '../../components/ContentLoader';
import { alertLoadingError } from '../../components/Confirm';
import '../../../i18n';
import { useTranslation } from 'react-i18next';

export default function Video({market}) {
    const [videos, setVideos] = useState(null)
    const [error, setError] = useState(false)
    const { t } = useTranslation();

    useEffect(() => {
        if (!videos) {
            axios({
                method: 'get',
                baseURL: process.env.REACT_APP_CONTURA_HEADLESS_BASE_URL,
                url: '/rest-api/mediaApi/videos',
                params: {
                    market: market
                }
            }).then((res) => {
                setVideos(res.data.items);
            }).catch((error) => {
                setError(true)
                alertLoadingError(t)
                console.error(error); //eslint-disable-line 
            });
        }
    }, [videos, t]);

    return (
        <div className="main-content">
            <div className="content-primary">
                <h1>{t('media.video.title')}</h1>
                <p>
                    {t('media.video.intro')}
                </p>
                <h6>
                    {t('media.video.instruction')}
                </h6>
            </div>
            <div className="content-secondary">
                {videos?.length > 0 &&
                    <VideoList items={videos} />
                }
                {!videos && !error &&
                    <ProductListLoader />
                }
            </div>
        </div>
    );
}