import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import Login from '../../pages/Login/';
import Account from '../../pages/User/account';
import Campaigns from '../../pages/MyPages/campaigns';
import CartContextProvider from '../../components/Context/cart/CartContext';
import Checkout from '../../pages/Shop/checkout';
import Complaint from '../../pages/Complaints/complaint';
import Complaints from '../../pages/Complaints/complaints';
import ComplaintNew from '../../pages/Complaints/complaint-new';
import ComplaintsHistory from '../../pages/Complaints/complaints-history';
import CPQ from '../../pages/CPQ/';
import Finance from '../../pages/Finance/';
import HeaderDefault from '../../components/Header/';
import HeaderShop from '../../components/Header/shop';
import Home from '../../pages/Home/';
import Invoices from '../../pages/Finance/invoices';
import Media from '../../pages/Media/';
import MediaCampaign from '../../pages/Media/campaign';
import MediaDop from '../../pages/Media/declaration-of-performance';
import MediaHighResolution from '../../pages/Media/high-resolution';
import MediaManuals from '../../pages/Media/manuals';
import MediaSigns from '../../pages/Media/signs';
import MediaVideo from '../../pages/Media/video';
import MyPagesProductsInShowroom from '../../pages/MyPages/products-in-showroom';
import MyPagesAddProducts from '../../pages/MyPages/add-products';
// import NavigationDownload from '../../components/Navigation/download';
import Navigation from '../../components/Navigation';
import News from '../../pages/MyPages/news';
import Article from '../../components/News/NewsArticle';
import NotFound from '../../pages/NotFound/';
import Orders from '../../pages/Orders/';
import OrderAdd from '../../pages/Orders/order-add';
import OrderHistory from '../../pages/Orders/order-history';
import Product from '../../pages/Product/';
import Shop from '../../pages/Shop/';
import ShoppingCart from '../../components/Cart/shopping-cart';
import Contact from '../../pages/Contact';
import NewsOverlay from '../News/NewsOverlay';
import UserAdmin from '../../pages/User/admin';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { InteractionStatus } from '@azure/msal-browser';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useAccount,
    useIsAuthenticated
} from '@azure/msal-react';
import { convertToSitevisionMarket } from '../Utils/market';




const App = () => {
    const { accounts, inProgress, instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const accountInfo = useAccount(accounts[0] || {});
    const { i18n } = useTranslation();
    const [isAdmin, setIsAdmin] = useState(false);
    const [sitevisionMarket, setSiteVisionMarket] = useState("");
    const [market, setMarket] = useState("")

    // const tagManagerArgs = {
    //     gtmId: 'GTM-KG7PW8K',
    // };
    //
    // TagManager.initialize(tagManagerArgs);

    useEffect(() => {
        if (isAuthenticated && inProgress === InteractionStatus.None) {

            //! "Hack" - Force logout after password change
            // API Connector in Azure doesn't get called in the
            // self service password reset flow (forgot password or mandatory
            // password change on first login) which results in missing
            // data on token. User needs to login in with normal sign_in flow again.
            // Could possibly be solved by setting up a custom policy in Azure.
            if (accountInfo.idTokenClaims.isForgotPassword) {
                instance.logoutRedirect().catch((e) => {
                    console.error(e); // eslint-disable-line
                });
            }

            const
                account = accountInfo?.idTokenClaims,
                custMarket = account.extension_market?.toLowerCase(),
                isAdminUser = account.extension_Roles?.toLowerCase().includes('portal admin');

            setMarket(custMarket)
            setSiteVisionMarket(convertToSitevisionMarket(custMarket));


            let lang;

            switch (custMarket) {
                case 'de':
                    lang = 'en'
                    break;

                case 'dk':
                    lang = 'en'
                    break;

                case 'fi':
                    lang = 'en'
                    break;

                case 'no':
                    lang = 'no'
                    break;

                case 'se':
                    lang = 'sv'
                    break;

                default:
                    lang = 'en'
            }
            i18n.changeLanguage(lang);
            setIsAdmin(isAdminUser)
        }
    }, [accountInfo, inProgress, i18n, isAuthenticated, instance]);

    let location = useLocation();

    let baseName =
        location.pathname === '/' ? 'home' : location.pathname.split('/')[1];

    let Header = () => {
        switch (baseName) {
            case 'shop':
                return <HeaderShop />;
            case 'cpq':
            case 'checkout':
                return '';
            default:
                return <HeaderDefault />;
        }
    };

    let hideOffCanvas = () => {
        document.documentElement.classList.remove('has-visible-mobile-menu');
        document.documentElement.classList.remove('has-visible-shopping-cart');
    };

    return (
        <>
            <AuthenticatedTemplate>
                <CartContextProvider>
                    {baseName !== 'cpq' ? (
                        <div
                            className={`page-content page-content-${baseName}`}
                        >
                            <NewsOverlay market={sitevisionMarket} />
                            <div className="main-container">
                                {Header(baseName)}
                                <Switch>
                                    <Route exact path="/">
                                        <Redirect to="/home" />
                                    </Route>
                                    <Route
                                        path="/account"
                                        component={Account}
                                    />
                                    <Route
                                        path="/campaigns"
                                        component={Campaigns}
                                    />
                                    <Route
                                        path="/checkout"
                                        component={Checkout}
                                    />
                                    <Route
                                        path="/complaints"
                                        component={Complaints}
                                    />
                                    <Route
                                        path="/complaints-history"
                                        component={ComplaintsHistory}
                                    />
                                    <Route
                                        path="/complaint/:incidentId?"
                                        component={Complaint}
                                    />
                                    <Route
                                        path="/complaint-new/:searchId?"
                                        component={ComplaintNew}
                                    />
                                    <Route
                                        path="/contact"
                                        component={Contact}
                                    />
                                    <Route
                                        path="/finance"
                                        component={Finance}
                                    />
                                    <Route path="/home" component={Home} />
                                    {isAdmin &&
                                        <Route
                                            path="/invoices"
                                            component={Invoices}
                                        />
                                    }
                                    <Route
                                        path="/media"
                                        render={(props) => <Media {...props} market={sitevisionMarket} />}
                                    />
                                    <Route
                                        path="/media-campaign"
                                        render={(props) => <MediaCampaign {...props} market={sitevisionMarket} />}
                                    />
                                    <Route
                                        path="/media-dop"
                                        render={(props) => <MediaDop {...props} market={sitevisionMarket} />}
                                    />
                                    <Route
                                        path="/media-high-resolution"
                                        render={(props) => <MediaHighResolution {...props} market={'image-global'} />}
                                    />
                                    <Route
                                        path="/media-manuals"
                                        render={(props) => <MediaManuals {...props} market={sitevisionMarket} />}
                                    />
                                    <Route
                                        path="/media-signs"
                                        render={(props) => <MediaSigns {...props} market={'global'} />}
                                    />
                                    <Route
                                        path="/media-video"
                                        render={(props) => <MediaVideo {...props} market={sitevisionMarket} />}
                                    />
                                    <Route
                                        path="/news/:id"
                                        component={Article}
                                    />
                                    <Route
                                        path="/my-pages/products-in-showroom"
                                        component={MyPagesProductsInShowroom}
                                    />
                                    <Route
                                        path="/my-pages/add-products"
                                        component={MyPagesAddProducts}
                                    />
                                    <Route
                                        exact
                                        path="/news"
                                        render={(props) => <News {...props} market={sitevisionMarket} />}
                                    />
                                    <Route
                                        path="/NotFound"
                                        component={NotFound}
                                    />
                                    <Route
                                        path="/order-add"
                                        component={OrderAdd}
                                    />
                                    <Route path="/orders" component={Orders} />
                                    <Route
                                        path="/order-history"
                                        component={OrderHistory}
                                    />
                                    <Route
                                        path="/shop/category/:categoryId"
                                        component={Shop}
                                    />
                                    <Route
                                        path="/shop/product/:productId"
                                        component={Product}
                                    />
                                    <Route
                                        path="/user-admin"
                                        component={UserAdmin}
                                    />
                                    <Route path="*" component={NotFound} />
                                </Switch>
                            </div>
                            <div
                                className="overlay"
                                onClick={hideOffCanvas}
                            ></div>
                            <Navigation />
                            <ShoppingCart />
                        </div>
                    ) : (
                        <>
                            <Route
                                path="/cpq"
                                render={(props) => <CPQ {...props} />}
                            />
                        </>
                    )}
                </CartContextProvider>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Route exact path="/" component={Login} />
                <Route path="/download" component={Login} /> {/* TEMP until correct component */}
                <Route path="*">
                    <Redirect to="/" />
                </Route>
            </UnauthenticatedTemplate>
        </>
    );
};

export default App;
