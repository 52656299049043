
/**
 * To match sitevision market structure
 */
export const convertToSitevisionMarket = (custMarket) => {
    let market;
    switch (custMarket) {
        case "se":
            market = "sv-SE"
            break;
        case "no":
            market = "no-NO";
            break;
        case "dk":
            market = "da-DK";
            break;
        case "de":
            market = "de-DE";
            break;
        case "fi":
            market = "fi-FI";
            break;
        default:
            market = undefined;
    }

    console.log("converted to : " + market)
    return market;
}

// Convert for XA & readablility
export const convertToCountry = (custMarket) => {
    let market = custMarket.toLowerCase().trim(),
        country;
    switch (market) {
        case "se":
            country = "Sverige";
            break;
        case "no":
            country = "Norge";
            break;
        case "dk":
            country = "Danmark";
            break;
        case "de":
            country = "Deutschland";
            break;
        case "fi":
            country = "Suomi";
            break;
        default:
            country = "";

    }
    return country;
}