import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { InteractionStatus } from '@azure/msal-browser';
import useAxios from 'scripts/hooks/useAxios';
import { useMsal, useAccount } from '@azure/msal-react';
import { TableLoader } from '../../components/ContentLoader';
import ErrorMessage from '../../components/Forms/error-message';
import { ReactComponent as IconClear } from '../../../images/icons/close.svg'

export default function Complaint(props) {
    const { callApi } = useAxios();
    let { incidentId } = useParams()
    const [complaint, setComplaint] = useState(null);
    const [comments, setComments] = useState(null);
    const [files, setFiles] = useState(null);
    const { t } = useTranslation();
    const { register, handleSubmit, reset, watch } = useForm({
        defaultValues: {
            comment: ''
        }
    });
    const { register: register2, handleSubmit: handleSubmit2, reset: reset2, formState: { errors: errors2 }, clearErrors: clearErrors2 } = useForm();
    const { accounts, inProgress } = useMsal();
    const [loadingPage, setLoadingPage] = useState(false);
    const [filesToSubmit, setFilesToSubmit] = useState([]);
    const [isSubmittingComments, setIsSubmittingComments] = useState(false);
    const
        accountInfo = useAccount(accounts[0] || {}),
        account = accountInfo.idTokenClaims,
        userName = account.name,
        userInitials = account.given_name.charAt(0) + account.family_name.charAt(0);

    const getComments = useCallback(() => {
        callApi({
            method: 'get',
            url: '/api/complaint/messages',
            params: {
                incidentId
            }
        }).then((response) => {
            setComments(response.data.value);
        }).catch((error) => {
            console.error(error); // eslint-disable-line no-console
        });
    }, [callApi, incidentId]);

    const getFiles = useCallback(() => {
        callApi({
            method: 'get',
            url: '/api/complaint/images',
            params: {
                incidentId
            }
        }).then((res) => {
            setFiles(res.data.value);
        }).catch((error) => {
            console.error(error); // eslint-disable-line no-console
        });
    }, [callApi, incidentId]);

    useEffect(() => {
        if (!complaint && inProgress === InteractionStatus.None) {
            callApi({
                method: 'get',
                url: '/api/complaint',
                params: {
                    incidentId
                }
            }).then((response) => {
                setComplaint(response.data.value[0]);
            }).catch((error) => {
                console.error(error); // eslint-disable-line no-console
            });
        }
    }, [complaint, inProgress]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!comments && inProgress === InteractionStatus.None) {
            getComments();
        }
    }, [comments, inProgress, getComments]);

    useEffect(() => {
        if (!files && inProgress === InteractionStatus.None) {
            getFiles();
        }
    }, [files, inProgress, getFiles]);

    //? DO WE NEED THIS FUNCTIONALITY?
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         getComments();
    //     }, 60000);

    //     return () => clearInterval(interval);
    // });

    const onSubmitComment = (data) => {
        setIsSubmittingComments(true)
        callApi({
            method: 'post',
            url: '/api/complaint/messages',
            data: {
                description: data.comment,
                incidentId
            }
        }).then((response) => {
            reset();
            getComments();
            setIsSubmittingComments(false)
        }).catch((error) => {
            console.error(error); // eslint-disable-line no-console
            setIsSubmittingComments(false)
        });
    };

    const onSubmitImage = (data) => {
        setLoadingPage(true);
        const formData = new FormData();
        formData.append('incidentId', incidentId);

        for (let i = 0; i < data.file.length; i++) {
            formData.append('file', data.file[i]);
        }

        callApi({
            method: 'post',
            url: '/api/complaint/images',
            data: formData
        }).then((res) => {
            reset2();
            getFiles();
            setLoadingPage(false);
            setFilesToSubmit([]);
        }).catch((err) => {
            reset2();
            setLoadingPage(false);
            setFilesToSubmit([]);
            console.error(err); // eslint-disable-line no-console
        });
    };

    const openImage = (itemId, self) => {
        self.classList.add('complaints-image-loading')
        callApi({
            method: 'get',
            url: '/api/complaint/image',
            params: {
                incidentId,
                itemId,
            }
        }).then((res) => {
            window.open(res.data.getUrl);
            self.classList.remove('complaints-image-loading')
        }).catch((err) => {
            console.error(err); // eslint-disable-line no-console
            self.classList.remove('complaints-image-loading')
        });
    };

    const nameToInitials = (name) => {
        if (name === null || name === undefined) {
            name = "";
        }
        return name.split(' ').map(i => i.charAt(0)).join('').toUpperCase()
    };

    const getFileInfo = (files) => { // FileList
        setFilesToSubmit(Array.from(files));
    }

    const clearFiles = () => {
        setFilesToSubmit([])
        document.querySelector('.file-upload').value = '';
        clearErrors2('file');
    }

    return (
        <div className="main-content">
            {complaint &&
                <>
                    {loadingPage &&
                        <div className="page-is-loading">
                            <svg className="loading-spinner" viewBox="0 0 50 50">
                                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                            </svg>
                        </div>
                    }
                    <div className="content-primary">
                        <h1>{t('complaints.complaint')} {complaint.ed_casenumber ? complaint.ed_casenumber : complaint.ticketnumber}</h1>
                        <p>{t('complaints.ordernumber')} {complaint.edp_ordernumber}</p>
                    </div>
                    <div className="content-secondary">

                        <form className="form-complaints" onSubmit={handleSubmit2(onSubmitImage)}>
                            {complaint.edp_incident_edp_claimproduct.length
                                ?
                                <>
                                    <p className="field-label"> {t('complaints.label.complaint-articles')}</p>
                                    <div className="complaint-article-items">
                                        <div className="complaint-article-item complaint-article-item-header">
                                            <span>{t('complaints.article-header.article-nr')}</span>
                                            <span>{t('complaints.article-header.article')}</span>
                                            <span>{t('complaints.article-header.quantity')}</span>
                                        </div>

                                        {complaint.edp_incident_edp_claimproduct.map((Line, index) => (
                                            <div key={index} className="complaint-article-item complaint-article-item-data">
                                                <span>{Line.edp_articlenumber}</span>
                                                <span>{Line.edp_name}</span>
                                                <span>{Line.edp_quantity}</span>
                                            </div>
                                        ))}
                                    </div>
                                </>
                                :
                                <p className="field-label">[ {t('complaints.no-articles-error')} ]</p>
                            }

                            {complaint.ed_serialnumber &&
                                <>
                                    <p className="field-label">{t('form.field.serialnumber')}</p>
                                    <input type="text" className="field field-text" defaultValue={complaint.ed_serialnumber} readOnly />
                                </>
                            }

                            <p className="field-label">{t('complaints.label.type-of-complaint')}</p>
                            <input type="text" className="field field-text" defaultValue={complaint.msdyn_incidenttype.msdyn_name} readOnly />
                            <textarea type="text" className="field field-textarea" defaultValue={complaint.description} readOnly />

                            <p className="field-label">{t('form.field.delivery-address')}</p>
                            {complaint.msdyn_FunctionalLocation ? (
                                <textarea type="text" className="field field-textarea" defaultValue={
                                    complaint.msdyn_FunctionalLocation.msdyn_address1 + "\r\n" +
                                    complaint.msdyn_FunctionalLocation.msdyn_postalcode + "\r\n" +
                                    complaint.msdyn_FunctionalLocation.msdyn_city}
                                    readOnly />
                            ) : complaint.primarycontactid ? (
                                <textarea type="text" className="field field-textarea" defaultValue={
                                    complaint.primarycontactid.address1_line1 + "\r\n" +
                                    complaint.primarycontactid.address1_postalcode + "\r\n" +
                                    complaint.primarycontactid.address1_city + "\r\n" +
                                    complaint.primarycontactid.address1_country}
                                    readOnly />
                            ) : null}

                            <p className="field-label">{t('complaints.label.order-mark')}</p>
                            <input type="text" className="field field-text" defaultValue={complaint.edp_ordermark} readOnly />

                            {files?.length ?
                                <>
                                    <p className="field-label">{t('complaints.label.attached-images')}</p>
                                    <div className="field field-text complaints-images">
                                        <ol>
                                            {files.map((file, index) => {
                                                if (file.name.match(/test2\.txt|text2\.txt|default\.txt|general\.txt/)) {
                                                    return null;
                                                } else {
                                                    return (
                                                        <li key={file.id}>
                                                            <span
                                                                className="complaints-image"
                                                                onClick={(e) => {
                                                                    openImage(file.id, e.currentTarget)
                                                                }}
                                                            >
                                                                <span className="complaints-image-title">{file.name?.replace(/^\d+-/, '')}</span>
                                                                <svg className="complaints-image-spinner" viewBox="0 0 50 50">
                                                                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                                                </svg>
                                                            </span>
                                                        </li>
                                                    );
                                                }
                                            })}
                                        </ol>
                                    </div>
                                </>
                                : null}

                            <div>
                                <p className="field-label">{t('complaints.label.complementary-images-and-files')} <span className='field-label--small'>{t('complaints.label.complementary-images-and-files-details')}</span></p>
                                <div className={`field-file${errors2?.file ? ' field-error' : ''}`}>
                                    <span className="field-file-placeholder">
                                        {filesToSubmit?.length ?
                                            <ol>
                                                {filesToSubmit.map((file, index) => (
                                                    <li key={index}>
                                                        {file.name}
                                                    </li>
                                                ))}
                                            </ol>
                                            : null}
                                    </span>
                                    <div className="field-file-buttons">
                                        {filesToSubmit?.length ?
                                            <span className="field-file-icon field-file-icon-clear"><IconClear onClick={clearFiles} /></span>
                                            : null}
                                        <div className={`field-file-button ${filesToSubmit?.length ? ' field-file-button-hidden' : ''}`}>
                                            <input
                                                className={`file-upload field ${errors2.file ? 'field-error' : ''}`}
                                                type="file"
                                                multiple
                                                accept="image/*,application/pdf"
                                                {...register2('file', {
                                                    required: t('form.file-error.file-required'),
                                                    onChange: (e) => getFileInfo(e.target.files),
                                                    validate: {
                                                        lessThan4MB: files => {
                                                            let allowed = true;
                                                            for (let i = 0; i < files.length; i++) {
                                                                if (files[i].size > 4000000) {
                                                                    allowed = false;
                                                                }
                                                            }
                                                            return allowed || t('form.file-error.file-size');
                                                        },
                                                        lessThan5Files: files => {
                                                            return files.length < 5 || t('form.file-error.file-amount', { maxFiles: 4 });
                                                        },
                                                        allowedTypes: files => {
                                                            let allowed = true;
                                                            for (let i = 0; i < files.length; i++) {
                                                                if (!files[i].type.match('image.*|application/pdf')) {
                                                                    allowed = false;
                                                                }
                                                            }
                                                            return allowed || t('form.file-error.file-type');
                                                        },
                                                    }
                                                })}
                                                name="file"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <ErrorMessage errors={errors2.file} />
                                <div className="buttongroup">
                                    &nbsp;
                                    <button type="submit" className="button-submit button-primary" disabled={!filesToSubmit?.length}>
                                        {filesToSubmit?.length > 1 ? t('complaints.add-files') : t('complaints.add-file')}
                                    </button>
                                </div>
                            </div>

                            <h2>{t('complaints.notes')}</h2>
                            {comments?.length ? (
                                <div className="comments comments-history">
                                    {comments.map((comment, index) => (
                                        <div key={index} className="comment">
                                            <div className="comment-header">
                                                <div
                                                    className={`comment-avatar${/Contura/i.test(comment.subject) ? ' comment-avatar-contura' : ''}`}
                                                >
                                                    {comment.name !== 'Contura' && nameToInitials(comment.subject)}
                                                </div>
                                                <div className="comment-name">{comment.subject}</div>
                                                <div className="comment-date">{new Date(comment.createdon).toLocaleString()}</div>
                                            </div>
                                            <div className="comment-text">{comment.description}</div>
                                        </div>
                                    ))}
                                </div>
                            ) : null
                            }
                        </form>
                        <form key={1} className="form-complaints" onSubmit={handleSubmit(onSubmitComment)}>
                            <div className="comments">
                                <div className="comment">
                                    <div className="comment-header">
                                        <div className="comment-avatar">{userInitials}</div>
                                        <div className="comment-name">{userName}</div>
                                    </div>
                                    <textarea
                                        className={`field field-textarea comment-text`}
                                        placeholder={t('complaints.write-your-message-here')}
                                        disabled={isSubmittingComments}
                                        {...register("comment")}
                                        maxLength="480"
                                        required
                                    ></textarea>
                                </div>
                            </div>
                            <div className="buttongroup">
                                &nbsp;
                                <button
                                    type="submit"
                                    className={`button-submit button-primary${isSubmittingComments ? ' btn-loading' : ''}`}
                                    disabled={watch('comment') === ''}
                                >
                                    <span>{t('button.add-note')}</span>
                                </button>
                            </div>
                        </form>

                    </div>
                </>
            }
            {!complaint &&
                <TableLoader />
            }
        </div>
    );
}